<template>
  <div>
    <span v-if="!zone">--</span>
    <b-row align-v="center" no-gutters v-else-if="edit">
      <b-col>
        <Input
          v-bind="inputConfig"
          @input="updateQuantity"
        />
      </b-col>
      <b-col class="flex-min" v-if="quota !== null">
        <span class="mx-1">/</span>
        <span>{{ quota }}</span>
      </b-col>
    </b-row>
    <span v-else>
      {{ zone.quantity }}
    </span>
  </div>
</template>

<script>
import Input from "@/components/Inputs/Input.component.vue";

export default {
  name: 'DDMCAF-TicketRequestTableZone',
  components: {
    Input,
  },
  props: {
    model: {type: Object, required: true},
    zoneId: {type: [String, Number], required: true},
    edit: {type: Boolean, required: false, default: false},
  },
  data: () => ({
    zone: null,
    inputConfig: {},
  }),
  computed: {
    quota() {
      if(!this.isComplimentary || !this.hasComplimentaryQuota || !this.zone){
        return null;
      }
      return this.zone.quota || 0;
    },
    isComplimentary() {
      return this.model.type === 'complimentary';
    },
    hasComplimentaryQuota() {
      return this.model.organization?.hasComplimentaryQuota;
    },
  },
  methods: {
    setZone() {
      if(!this.model || !this.zoneId){
        return;
      }
      this.model.zones.forEach((zone) => {
        if (zone.ticketZoneId === this.zoneId) {
          this.zone = Object.assign(zone, {
            newQuantity: zone.quantity || 0,
          });
        }
      });

      this.setInputConfig();
    },
    updateQuantity(quantity) {
      if(!this.zone){
        return;
      }

      //for some reason chrome gets an Event here while firefox get the new value so conversion is needed
      if(quantity instanceof Event) {
        quantity = quantity.target?.value;
      }

      this.zone.newQuantity = parseInt(quantity, 10) || 0;

      this.setInputConfig();
    },
    setInputConfig() {
      this.inputConfig = {
        value: (this.zone ? this.zone.newQuantity : ''),
        type: 'number',
        min: 0,
        max: (this.quota !== null ? this.quota : 10000),
        step: 1,
        class: 'm-0',
        inputClass: {
          'p-2': true,
          'border-danger': (this.quota !== null && this.zone && this.zone.newQuantity > this.quota),
        },
      };
    },
  },
  watch: {
    model() {
      this.setZone();
    },
  },
  async created() {
    this.setZone();
  },
}
</script>
