import {ApiRequestor} from "@/classes/requestors/api.requestor";
import get from "lodash.get";

export class DtoApiUpdateContact extends ApiRequestor {
  async $update(ticketRequestId, organizationId, data) {
    const payload = {
      organizationId,
      matchId: get(data, 'matchId'),
      type: get(data, 'type'),
      zones: get(data, 'zones', []),
    }

    return this.$http('POST', `ticketRequests/${ticketRequestId}`, {payload});
  }
}
