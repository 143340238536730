<template>
  <section class="TicketRequests-View">
    <SpaceDescription>
      <Headline :size="1">Ticket Requests</Headline>
    </SpaceDescription>

    <TicketRequestListTable />
  </section>
</template>

<script>
import SpaceDescription from "@/components/SpaceDescription/SpaceDescription.component";
import Headline from "@/components/Headline/Headline.component";
import TicketRequestListTable from '@/components/Table/tables/TicketRequestListTable.component';
import RouterNames from "@/router/route.names";

export default {
  components: {
    SpaceDescription,
    Headline,
    TicketRequestListTable
  },
  methods: {
    checkPermissions() {
      const permitted = this.$store.getters['user/haveAccessToTickets'];
      if (!permitted) this.$router.push({name: RouterNames.Home})
    }
  },
  created() {
    this.checkPermissions()
    this.$store.dispatch('event/getMatchDays')
  }
}
</script>
