<template>
  <section class="TicketsTable" :style="{minWidth: '800px'}">
    <Button
      v-if="$store.getters['user/permissions'].includes('ticketRequests:createAPI')"
      type="primary"
      class="mb-3"
      @click="redirectToRequestTicket()"
    >
      Submit new request
    </Button>
    <div class="TicketsTable__filters justify-content-between row">
      <div class="col-md-4">
        <InputDropdown
            placeholder="Select matchday"
            :allow-empty="false"
            trackLabel="optionLabel"
            :options="matchDaysList"
            v-model="matchDay"
        />
      </div>
      <div class="col-md-4">
        <InputDropdown
            placeholder="Select match"
            :allow-empty="false"
            trackLabel="name"
            :options="matchesList"
            v-model="match"
        />
      </div>
      <div class="col-md-4">
        <InputSearch
          placeholder="Search by First name, Last name, City, Team"
          @searchkey="searchTickets"
          @clearFilters="clearFilters"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-3">
        <Button
          class="button-delete-bulk" type="outline-dark"
          :disabled="this.selectedRows.length === 0 || Boolean(editItem)"
          @click="deleteTicketRequestBulk()"
          icon="BIN_OUTLINE"
        >
          Delete ticket requests
        </Button>
      </div>
    </div>

    <Table
        :collection="ticketRequestList"
        :columns="tableColumns"
        :setRowClasses="setRowClasses"
        @on-sort="onSort"
    >
      <template v-slot:table-cell-requestDate="slot">
        {{ moment(slot.data).format('DD.MM.YYYY') }}
      </template>
      <template v-slot:table-cell-match="slot">
        {{ slot.model.match.name || '--' }}
      </template>
      <template v-slot:table-cell-organization="slot">
        {{ getOrganization(slot) || '--' }}
      </template>
      <template v-slot:table-cell-firstname="slot">
        {{ getFromModel(slot.model, 'mainContactOverwrite.firstname', getFromModel(slot.model, 'mainContact.firstname')) }}
      </template>
      <template v-slot:table-cell-lastname="slot">
        {{ getFromModel(slot.model, 'mainContactOverwrite.lastname', getFromModel(slot.model, 'mainContact.lastname')) }}
      </template>
      <template v-slot:table-cell-city="slot">
        <span class="with-word-break">
        {{ getFromModel(slot.model, 'mainContactOverwrite.city', getFromModel(slot.model, 'mainContact.contact.city')) }}
        </span>
      </template>
      <template v-slot:table-cell-country="slot">
        {{ getFromModel(slot.model, 'mainContactOverwrite.country.name', getFromModel(slot.model, 'mainContact.contact.country.name')) }}
      </template>
      <template v-slot:table-cell-zone1="slot">
        <TicketRequestTableZone
          :model="slot.model"
          :zoneId="tableZones[0]?.ticketZoneId"
          :edit="isDisabledEdit(slot.model)"
        />
      </template>
      <template v-slot:table-cell-zone2="slot">
        <TicketRequestTableZone
          :model="slot.model"
          :zoneId="tableZones[1]?.ticketZoneId"
          :edit="isDisabledEdit(slot.model)"
        />
      </template>
      <template v-slot:table-cell-zone3="slot">
        <TicketRequestTableZone
          :model="slot.model"
          :zoneId="tableZones[2]?.ticketZoneId"
          :edit="isDisabledEdit(slot.model)"
        />
      </template>
      <template v-slot:table-cell-zone4="slot">
        <TicketRequestTableZone
          :model="slot.model"
          :zoneId="tableZones[3]?.ticketZoneId"
          :edit="isDisabledEdit(slot.model)"
        />
      </template>
      <template v-slot:table-cell-status="slot">
        <span :style="{ color: getStatusColor(slot.data) }">
        {{ slot.model.status ? getStatus(slot.model) : '--' }}
          </span>
      </template>
      <template v-slot:table-cell-type="slot">
        <span class="with-word-break">
        {{ slot.model.type ? slot.model.type.charAt(0).toUpperCase() + slot.model.type.slice(1) : '--' }}
        </span>
      </template>
      <template v-slot:table-cell-rowSelect="slot">
        <input
          type="checkbox"
          class="TeamTable__checkbox-select rowSelect"
          v-model="selectedRows"
          :value="slot.model.ticketRequestId"
          :disabled="slot.model.status !== 'requested' || editItem"
        />
      </template>
      <template v-slot:table-header-bulk>
        <input
          type="checkbox"
          class="checkAll"
          v-model="checkAll"
          :disabled="editItem"
        />
      </template>
      <template v-slot:table-cell-actions="slot">
        <Button
            class="TicketsTable__button-delete"
            @click="deleteTicketRequest(slot.model)"
            icon="BIN_OUTLINE"
            type="round"
            :disabled="slot.model.status !== 'requested' || Boolean(editItem)"
        />
        <Button
            class="TicketsTable__button-edit"
            icon="EDIT_OUTLINE"
            form="round"
            type="primary"
            @click.native="enableEdit(slot.model)"
            :disabled="slot.model.status !== 'requested'"
            v-if="!editItem"
        />
        <Button
            form="round"
            type="primary"
            icon="RIGHT_OUTLINE"
            @click.native="redirectToDetails(slot.model.ticketRequestId, slot.model)"
            v-if="!editItem"
        />

        <b-icon-x-circle
          variant="danger"
          class="icon-action"
          @click="disableEdit"
          v-if="isDisabledEdit(slot.model) && !showLoader"
        />
        <b-icon-check-circle
          variant="primary"
          class="icon-action"
          :disabled="showLoader"
          @click="updateTicketRequest(editItem)"
          v-if="isDisabledEdit(slot.model) && !showLoader"
        />

        <pulse-loader :loading="showLoader" :color="loaderColor" v-if="isDisabledEdit(slot.model) && showLoader"/>
      </template>
    </Table>
    <TableControls @setLimit="updateLimit($event)" @selectPage="updatePage($event)"
                   :count="count" :limit="limit" :offset="offset"/>

    <vue-confirm-dialog class="confirm-dialog"></vue-confirm-dialog>
  </section>
</template>

<script>
import upperFirst from 'lodash.upperfirst';
import moment from "moment";

import Table from '@/components/Table/Table.component';
import TableControls from "@/components/Table/TableControls.component";
import InputDropdown from "@/components/Inputs/InputDropdown.component";
import Button from "@/components/Button/Button.component";
import InputSearch from "@/components/Inputs/InputSearch.component.vue";
import TicketRequestTableZone from "@/components/Table/segments/TicketRequestListTableZone.vue"
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

import RouterNames from '@/router/route.names';

import {TableColumnsCollection} from "@/components/Table/classes/TableColumns.collection";
import {TableColumnModel} from "@/components/Table/classes/TableColumn.model";
import get from "lodash.get";
import {
  DtoApiDeleteRequestTicketRequest
} from "../../../classes/dto/api/ticket/dto.api.delete-request-ticket.request";
import {DtoApiUpdateContact} from "@/classes/dto/api/ticket/dto.api.update-request-ticket.request";

export default {
  name: 'DDMCAF-TicketRequestListTable',
  components: {
    InputSearch, Table, Button, InputDropdown, TableControls, TicketRequestTableZone, PulseLoader
  },
  data: () => ({
    matchDay: null,
    match: null,
    tableColumns: new TableColumnsCollection(),
    tableZones: [],
    selectedRows: [],
    searchText: '',
    editItem: null,
    showLoader: false,
    loaderColor: "#00A9A0",
  }),
  watch: {
    matchDay(newVal) {
      if (newVal) {
        this.getTickets(this.requestPayload)
        this.getMatches(newVal)
      }
    },
    match(newVal) {
      if (newVal) {
        this.getTickets(this.requestPayload)
      }
    },
    searchText() {
      this.getTickets(this.requestPayload)
    },
    account(account) {
      this.pageMemo = this.page;
      this.page = 1;
      this.getTickets(account.id);
    },
  },
  computed: {
    requestPayload(){
      return Object.assign({}, this.match || this.matchDay, {
        searchText: this.searchText,
      })
    },
    checkAll: {
      get: function () {
        return this.selectedRows.length > 0;
      },
      set: function (value) {
        var checked = [];
        if (value) {
          this.ticketRequestList.models.forEach(function (model) {
            if(model.status === 'requested') {
              checked.push(model.ticketRequestId);
            }
          });
        }
        this.selectedRows = checked;
      }
    },
    requestsListMeta: {
      get: function () {
        return get(this, '$store.state.ticketRequestList.ticketRequestListMeta')
      },
    },
    account() {
      return get(this, '$store.state.user.account', []);
    },
    pageComputed() {
      return this.request.$loading
          ? this.pageMemo
          : this.page;
    },
    ticketRequestList() {
      return get(this, '$store.state.ticketRequestList.ticketRequestList')
    },
    matchDaysList() {
      return get(this, '$store.state.event.matchDaysList')
    },
    matchesList() {
      return get(this, '$store.state.event.matchesList')
    },
    count() {
      return this.requestsListMeta ? this.requestsListMeta.count : 0;
    },
    offset() {
      return this.requestsListMeta ? this.requestsListMeta.offset : 0;
    },
    limit: {
      get: function () {
        return get(this, '$store.state.ticketRequestList.limit')
      },
      set: function (newVal) {
        this.$store.state.ticketRequestList.limit = newVal
      }
    },
    page: {
      get: function () {
        return get(this, '$store.state.ticketRequestList.page')
      },
      set: function (newVal) {
        this.$store.state.ticketRequestList.page = newVal
      }
    },
    pages: {
      get: function () {
        return get(this, '$store.state.ticketRequestList.pages')
      },
      set: function (newVal) {
        this.$store.state.ticketRequestList.pages = newVal
      }
    },
    orderBy: {
      get: function () { return get(this, '$store.state.ticketRequestList.orderBy') },
      set: function (newVal) { this.$store.state.ticketRequestList.orderBy = newVal }
    },
    orderByDirection: {
      get: function () {
        return get(this, '$store.state.ticketRequestList.orderByDirection')
      },
      set: function (newVal) {
        this.$store.state.ticketRequestList.orderByDirection = newVal
      }
    },
  },
  methods: {
    async deleteTicketRequest(ticket) {
      this.$confirm(
          {
            message: `Are you sure you want to delete this ticket request?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                new DtoApiDeleteRequestTicketRequest().$deleteTicketRequest([ticket.ticketRequestId], this.$store.state.user.account.id).then(() => {
                  this.getTickets()
                })
              }
            }
          })
    },
    async deleteTicketRequestBulk() {
      if (!this.selectedRows.length) {
        return;
      }

      this.$confirm(
        {
          message: `Are you sure you want to delete selected ticket requests?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: confirm => {
            if (confirm) {
              new DtoApiDeleteRequestTicketRequest().$deleteTicketRequest(this.selectedRows, this.$store.state.user.account.id).then(() => {
                this.getTickets()
              })
            }
          }
        })
    },
    async updateTicketRequest(ticket){
      const payload = {
        matchId: ticket.match?.matchId,
        type: ticket.type,
      };

      payload.zones = ticket.zones.map(({newQuantity, ticketZoneId}) => ({
        zoneId: ticketZoneId,
        quantity: Number(newQuantity),
      }));

      this.showLoader = true;
      const result = await new DtoApiUpdateContact().$update(ticket.ticketRequestId, this.$store.state.user.account.id, payload);
      this.showLoader = false;

      if(!result.$error) {
        for (const i in this.ticketRequestList.models) {
          if (this.ticketRequestList.models[i].ticketRequestId === ticket.ticketRequestId && result.data) {
            this.$set(this.ticketRequestList.models, i, result.data);
          }
        }

        this.disableEdit();
      }
    },
    moment,
    upperFirst,
    getOrganization(slot) {
      return slot.model.organization.name
    },
    getFromModel(model, path, fallback) {
      const data = get(model, path) || fallback;

      return data ? data : '--'
    },
    getStatusColor(status) {
      return ({
        approved: '#7fcc90',
        rejected: '#f68476',
        review: '#fac991',
        draft: '#a8a8a8'
      })[status] || '#a8a8a8';
    },
    redirectToDetails(request) {
      if (!request) return;
      this.$router.push({
        name: RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.TicketRequestDetails,
        params: {request}
      })
    },
    redirectToRequestTicket() {
      this.$router.push({name: RouterNames.IndexRoutes.DashboardRoutes.TicketRequest})
    },
    onSort(event) {
      if (event.sort) {
        this.orderBy = event.sort
        this.orderByDirection = this.orderByDirection === 'desc' ? 'asc' : 'desc'
        this.getTickets()
      }
    },
    updateLimit(limit) {
      this.limit = limit;
      this.page = 1;
      this.getTickets()
    },
    updatePage(page) {
      this.page = page;
      this.getTickets()
    },
    async getTickets(data) {
      await this.$store.dispatch('ticketRequestList/getTicketRequests', data)
    },
    getMatches(data) {
      this.$store.dispatch('event/getMatches', {...data, withRequestsFor: 'ticket_request'})
    },
    searchTickets(searchText){
      this.searchText = searchText;
    },
    clearFilters() {
      if (this.matchDay || this.match || this.searchText) {
        this.matchDay = null
        this.match = null
        this.searchText = ''
        this.getTickets()
      }
    },
    setTableColumns() {
      let models = [
        new TableColumnModel('rowSelect', '', '40px'),
        new TableColumnModel('requestDate', 'Request Date', '100px', 'created_at'),
        new TableColumnModel('match', 'Match', '150px'),
        new TableColumnModel('firstname', 'First Name', '100px'),
        new TableColumnModel('lastname', 'Last Name', '100px'),
        new TableColumnModel('city', 'City', '100px'),
        new TableColumnModel('country', 'Country', '100px'),
        new TableColumnModel('type', 'Type', '110px', 'type'),
      ]

      let zones = []
      let addedZoneIds = []
      if (this.ticketRequestList.models) {
        this.ticketRequestList.models.forEach((requestItem) => {
          requestItem.zones.forEach((zoneItem) => {
            if (!addedZoneIds.includes(zoneItem.ticketZoneId)) {
              zones.push(zoneItem)
              addedZoneIds.push(zoneItem.ticketZoneId)
            }
          })
        })

        zones.sort((a, b) => (a.customOrder > b.customOrder) ? 1 : ((b.customOrder > a.customOrder) ? -1 : 0))

        this.tableZones = zones
        let i = 1
        zones.forEach((zoneItem) => {
          models.push(new TableColumnModel('zone' + i, zoneItem.name, '70px'))
          i++;
        })
      }

      models.push(new TableColumnModel('status', 'Status', '80px', 'status'))
      models.push(new TableColumnModel('actions', '', '115px'))

      this.tableColumns = new TableColumnsCollection(models);
    },
    getStatus(model) {
      let result = model.status.charAt(0).toUpperCase() + model.status.slice(1)
      if (model.status === 'in_review') {
        result = 'In Review'
      }

      return result
    },
    enableEdit(ticketRequest) {
      this.editItem = ticketRequest;
    },
    disableEdit() {
      this.editItem = null;
    },
    isDisabledEdit(item) {
      return this.editItem && this.editItem.ticketRequestId === item.ticketRequestId;
    },
    setRowClasses(item) {
      if(!this.editItem || this.isDisabledEdit(item)){
        return [];
      }
      return ['bg-disabled'];
    },
  },
  async created() {
    await this.getTickets()

    this.setTableColumns(window.outerWidth)
  },
}
</script>

<style lang="scss">
@import "src/styles/main.scss";

.confirm-dialog {
  .vc-btn:last-child {
    color: $danger-color;
  }

  .vc-btn:first-child {
    color: $primary-color;
  }
}

.TicketsTable {
  .button-component__icon {
    font-size: 28px !important;
  }

  .Table__table-cell-container {
    justify-content: space-between;
  }

  &__button-delete {
    height: auto;
    background: none;

    .button-component__icon {
      color: $danger-color !important;
      transform: none;
    }
  }

  &__button-delete,
  &__button-edit {
    &[disabled] {
      visibility: hidden;
      pointer-events: none;
    }
  }

  &__filters-input-wrapper {
    display: flex;
  }
}

.bg-disabled {
  background-color: #D8DAE3;
}

.icon-action {
  font-size: 1.75rem;
  vertical-align: bottom !important;
  cursor: pointer;
}

.flex-min {
  flex-grow: 0;
  flex-basis: content;
}

</style>
